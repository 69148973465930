import _ from 'lodash';
import React from 'react';
import { StaticQuery, Link, graphql } from 'gatsby';
import Section from '../Section';

import styles from '../../sidebar.module.scss';

const Categories = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allWordpressCategory(sort: { fields: slug, order: ASC }) {
            edges {
              node {
                name
                slug
                id
                count
                path
              }
            }
          }
        }
      `}
      render={data => {
        const categories = data.allWordpressCategory.edges;
        const categoryLinks = _.map(categories, ({ node }) => {
          const { id, name, slug, count } = node;
          if (count < 1) {
            return null;
          }
          return (
            <div key={id} className={styles.categoryItem}>
              <Link to={`/blog/category/${slug}`}>{_.toLower(name)}</Link>
            </div>
          );
        });
        return <Section sectionName="Categories">{categoryLinks}</Section>;
      }}
    />
  );
};

export default Categories;
