import _ from 'lodash';
import React from 'react';
import { StaticQuery, Link, graphql } from 'gatsby';
import Section from '../Section';
import { cleanTitle } from '../../../../utils/postUtils';

import styles from '../../sidebar.module.scss';

const Recent = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allWordpressPost(sort: { fields: [date], order: DESC }, limit: 5) {
            edges {
              node {
                id
                slug
                title
              }
            }
          }
        }
      `}
      render={data => {
        const posts = data.allWordpressPost.edges;
        const recentPosts = _.map(posts, ({ node }) => {
          const { id, title, slug } = node;
          return (
            <div key={id} className={styles.sectionLink}>
              <Link to={`/blog/${slug}`}>
                <p dangerouslySetInnerHTML={{ __html: cleanTitle(title) }} />
              </Link>
            </div>
          );
        });
        return <Section sectionName="Recent Posts">{recentPosts}</Section>;
      }}
    />
  );
};

export default Recent;
